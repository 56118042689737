<template lang="pug">
  section(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/registro_bg.jpg') + ')'} ")
    <!-- BEGIN LOADER -->
    div(id="load_screen") 
        div.loader
            div.loader-content
        div.spinner-grow.align-self-center
    <!--  END LOADER -->

    div.form-container.outer
        div.form-form
            div.form-form-wrap
                div.form-container
                    div.form-content
                        img(src="../assets/img2/insigne_logo_registro.png")

                        h3 {{ $t('register.register') }}
                        p.signup-link.register {{ $t('register.haveAccount') }}
                            router-link(to="/") {{ $t('login.btnLogin') }} 
                        vue-form.text-left(:state="formstate")
                            div.form
                                div.form-row
                                    div.form-group.col-md-6
                                        validate(tag="div" )
                                            input.form-control(type="name" name="sName" required :placeholder="$t('register.name')" v-model="model.sName")
                                            field-messages.text-danger(name="sName" )
                                                div(slot="required") {{ $t('form.requireMessage', [$t('register.name')]) }} 
                                    div.form-group.col-md-6
                                        validate(tag="div")
                                            input.form-control(type="name" name="sLstName" required :placeholder="$t('register.surnames')" v-model="model.sLastName")
                                            field-messages.text-danger(name="sLstName" )
                                                div(slot="required") {{ $t('form.requiredMessageP', [$t('register.surnames')]) }} 
                              
                                div.form-row
                                    div.form-group.col-md-6
                                        select.form-control(id="inputCountry" v-model="idCountry" @change="getStates")
                                            option(value="0" selected) {{ $t('form.DropDownM', [$t('register.country')]) }}
                                            option(:value=" country.id" v-for="country in countries") {{ country.option }}
                                        
                                    div.form-group.col-md-6
                                        select.form-control(id="inputState" v-model="model.idState")
                                            option(value="0") {{ $t('form.DropDownF', [$t('register.state')]) }}
                                            option(:value="state.id" v-for="state in states") {{ state.option }}                                        
                              
                                div.form-row
                                    div.form-group.col-sm-6
                                        validate(tag="div")
                                            input.form-control(type="text" required name="sCity" id="inputCity" :placeholder="$t('register.city')" v-model="model.sCity")
                                            field-messages.text-danger(name="sCity")
                                                div(slot="required") {{ $t('form.requiredMessageF', [$t('register.city')]) }}
                                        
                                    div.form-group.col-sm-6
                                        validate(tag="div")
                                            input.form-control(type="number" name="zipCode" required  :placeholder="$t('register.zip')" v-model="model.sPostalCode")
                                            field-messages.text-danger(name="zipCode")
                                                div(slot="required") {{ $t('form.requireMessage', [$t('register.zip')]) }}
                                                div(slot="number") Código postal solo numeros
                              
                                div.form-group
                                    label(for="") {{ $t('register.birthday') }}
                                    input.form-control(id="basicFlatpickr" v-model="dBirthDay" type="date" :placeholder="$t('register.birthday')")
                              
                                div.form-group
                                    validate(tag="div")
                                        input.form-control(type="email" name="sEmail" required  :placeholder="$t('register.email')" v-model="model.sEmail")
                                        field-messages.text-danger(name="sEmail")
                                            div(slot="required") {{ $t('form.requireMessage', [$t('register.email')]) }}
                                            div(slot="email") {{ $t('register.badEmail') }}
                              
                                div.form-group
                                    validate(tag="div")
                                        input.form-control(type="password" name="sPassword" required :placeholder="$t('register.password')" v-model="model.sPassword")
                                        field-messages.text-danger(name="sPassword")
                                            div(slot="required") {{ $t('form.requiredMessageF', [$t('register.password')]) }}
                              
                                div.form-group
                                    validate(tag="div")                                
                                        input.form-control(type="password" required name="sRePassword" :placeholder="$t('register.passConfirm')" v-model="sPasswordConfirm")
                                        field-messages.text-danger(name="sRePassword")
                                            div(slot="required") {{ $t('form.requiredMessageF', [$t('register.passConfirm')]) }}


                                //div.field-wrapper.terms_condition
                                    div.n-chk
                                        label.new-control.new-checkbox.checkbox-primary
                                          input.new-control-input(type="checkbox")
                                          span.new-control-indicator
                                          span Acepto los 
                                            a(href="javascript:void(0);") términos y condiciones//

                                div.d-sm-flex.justify-content-between
                                    div.field-wrapper
                                        button.btn.btn-primary(type="button" @click="SaveData") {{ $t('register.registerLabel') }}
                                            i.bi.bi-check-circle

</template>
<script>
/* eslint-disable */
export default {
    name: 'Register',
    data() {
        return {
            formstate: {},
            idCountry: 0,
            countries: [],
            sPasswordConfirm : '',
            dBirthDay: new Date().toISOString().substr(0, 10),
            model: {
                sName: '',
                sLastName: '',
                idState: 0,
                sCity: '',
                sPostalCode: 0,
                dBirthDate: '',
                sEmail: '',
                sPassword: ''
            },
            states: []
        }
    },
    methods: {
        async getCountries() {
            await axios.get(apiURL + 'Common/getCountries?idLanguage=2')
            .then( (response) => {
                this.countries = response.data.options
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },

        async getStates() {
            this.openLoading('Espere por favor...')
            await axios.get(apiURL + 'Common/getStates?idCountry=' + this.idCountry)
            .then( (response) => {
                Swal.close()
                this.states = response.data.options
            } )
            .catch( (error) => {
                Swal.close()
                console.log(error)
            } )
        },

        async SaveData() {
            if(this.formstate.$invalid) {
                return;
            }

            this.openLoading('Guardando Datos ...');
            if( this.model.sPassword != this.sPasswordConfirm)
            {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El Password no coincide!!!',
                    footer: ''
                })
                return
            }
            
            var date = new Date(this.dBirthDay);
            var year = date.getFullYear();
            var month = date.getMonth()+1;
            var dt = date.getDate();

            if (dt < 10) {
            dt = '0' + dt;
            }
            if (month < 10) {
            month = '0' + month;
            }

            this.model.dBirthDate = dt + '/' + month + '/' + year
            console.log("cadena" + this.model.iPostalCode)

            await axios.post(apiURL + 'User/Create', this.model)
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire('Datos Guardados correctamente')
                router.push('/')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        }
    },
    mounted() {
        var loaderElement = document.querySelector('#load_screen');
        setTimeout( function() {
            loaderElement.style.display = "none";
        }, 3000);
        
        this.getCountries()
    },
    beforeMount() {
        document.body.classList.add('hold-transition', 'register-page')
    },
    destroyed() {
        document.body.classList.remove('hold-transition', 'register-page')
    }
}
</script>